import React, { useState, ChangeEvent, InputHTMLAttributes } from "react"
import cx from "classnames"
import { Form, Input, Radio, message } from "antd"
import InputMask from "react-input-mask"
import firebase from "gatsby-plugin-firebase"

import Button from "../../../../components/Button"
import Badges from "../../../../components/Badges"
import { eneAcademyFormBadges } from "../../../../mock/data"

import "./style.less"
import * as styles from "./style.module.css"

interface SubscriptionProps {
  fieldName: string
}

const SubscriptionForm = () => {
  const [form] = Form.useForm()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [hasExperience, setHasExperience] = useState(false)

  const gridClasses = cx("main-container flex justify-center m-auto")
  const sectionClasses = cx(styles.section, "w-full py-14")
  const boxFormClasses = cx(
    styles.containerForm,
    "border-solid bg-elephant border-2 pt-12 px-8 p-8 border-aquamarine rounded-3xl"
  )

  const handleSubmit = () => {
    firebase
      .firestore()
      .collection("ene-academy-01")
      .add({
        name: name,
        phone: phone,
        email: email,
        hasExperience: hasExperience,
        createdAt: firebase.firestore.Timestamp.now()
          .toDate()
          .toLocaleString("pt-br"),
      })
      .then(() => {
        onReset()
        message.success({
          content: "Formulário enviado com sucesso!",
          className: "custom-message",
        })
      })
      .catch((error) => {
        message.error({
          content: "Erro ao enviar o formulário!",
          className: "custom-message-error",
        })
      })
  }

  const handleChange = ({ fieldName }: SubscriptionProps) => {
    const { name, phone, email, hasExperience } = form.getFieldValue(fieldName)

    setName(name)
    setPhone(phone)
    setEmail(email)
    setHasExperience(hasExperience)
  }

  const onReset = () => {
    form.resetFields()
  }

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = e.target.value.replace(/\D/g, "")
    setPhone(phoneNumber)
  }

  return (
    <section id="form" className={sectionClasses}>
      <div className={gridClasses}>
        <div className={boxFormClasses}>
          <div className="flex">
            {eneAcademyFormBadges.map((item, index) => {
              return (
                <Badges
                  text={item.text}
                  icon={item.icon}
                  key={index}
                  index={index}
                />
              )
            })}
          </div>
          <h1 className="text-white lg:text-6xl text-4xl font-bold my-2 lg:m-0">
            Curtiu o projeto?
          </h1>
          <p className="text-white mb-8">Então vem crescer com a gente!</p>
          <Form
            name="ene-academy"
            form={form}
            onFinish={handleSubmit}
            onChange={handleChange}
            className="subscriptionForm"
            validateMessages={{
              required: "O campo é obrigatório.",
              types: {
                email: "Insira um e-mail válido.",
              },
            }}
          >
            <Form.Item
              rules={[{ required: true }]}
              {...form.getFieldValue("name")}
              name="name"
            >
              <Input placeholder="Nome" />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  pattern: /\(\d{2,}\) \d{4,}\-\d{4}/,
                  message: "Insira um número de telefone válido.",
                  required: true,
                },
              ]}
            >
              <InputMask
                mask={
                  phone?.length <= 10 ? "(99) 9999-9999?" : "(99) 99999-9999"
                }
                onChange={handleChangePhone}
                formatChars={{ 9: "[0-9]", "?": "[0-9 ]" }}
              >
                {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
                  <Input {...inputProps} placeholder="Telefone" />
                )}
              </InputMask>
            </Form.Item>
            <Form.Item name="email" rules={[{ type: "email", required: true }]}>
              <Input placeholder="E-mail" />
            </Form.Item>
            <div className="flex justify-start flex-wrap lg:justify-evenly mb-8 ">
              <span className="text-white flex items-center font-bold mb-1 lg:mb-0">
                Já possui experiência com Front-End?
              </span>

              <div className="w-full lg:w-auto">
                <Form.Item name="hasExperience">
                  <Radio.Group>
                    <Radio value="true">Sim</Radio>
                    <Radio value="false">Não</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
            <div className="flex justify-center">
              <Button preset="solidCyan" type="submit">
                Inscreva-se agora
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </section>
  )
}
export default SubscriptionForm
