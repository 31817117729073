import React from "react"
import cx from "classnames"

import _map from "lodash/map"
import Badges from "../Badges"
import Button from "../Button"
import { IMAGES } from "../../images"

import * as styles from "./style.module.css"

export interface Props {
  title: String
  badges: Array<{
    icon?: string
    text: string
  }>
  content: Array<{
    image: string
    text: string
    alternativeText: string
  }>
  button: {
    label: String
    preset: String
    type: String
    onClick: () => void
  }
}

const IconContent = ({
  title,
  badges,
  content,
  button,
}: Props): JSX.Element => {
  const sectionClasses = cx("main-container m-auto py-8 lg:py-16")
  const iconContentClasses = cx(
    styles.boxIconContent,
    "flex items-center lg:w-3/12 w-full py-3 px-3.5 mb-6 lg:mr-6"
  )
  return (
    <section className={styles.container} id="program">
      <div className={sectionClasses}>
        <div className="flex md:mx-auto md:w-10/12">
          {badges.map((item, index) => {
            return (
              <Badges
                key={`${item} - ${index}`}
                text={item.text}
                icon={item.icon}
                index={index}
              />
            )
          })}
        </div>
        <h1 className="font-bold text-white text-5xl mb-6 lg:mb-16 md:mx-auto md:w-10/12">
          {title}
        </h1>
        <div className="flex lg:mb-12 mb-8 lg:w-full lg:flex-nowrap flex-wrap">
          {content.map((item, index) => {
            return (
              <div key={item.text} className={iconContentClasses}>
                {_map(IMAGES, (image, index) => {
                  if (item.image === image.label) {
                    return (
                      <img
                        key={`${item.alternativeText} - ${index}`}
                        className="mr-8"
                        src={image.content.default}
                        alt={item.alternativeText}
                      />
                    )
                  }
                })}
                <span className="font-barlow text-white font-bold">
                  {item.text}
                </span>
              </div>
            )
          })}
        </div>
        <div className="flex justify-center">
          <Button
            preset={button.preset}
            onClick={button.onClick}
            type={button.type}
          >
            {button.label}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default IconContent
