import React from "react"

import Menu from "../../components/Menu"
import Hero from "../../components/Hero"
import SubscriptionForm from "./components/SubscriptionForm"
import Footer from "../../components/Footer"
import Timeline from "../../components/Timeline"
import IconContent from "../../components/IconContent"

import {
  eneAcademyIconContent,
  infoTimelineEneAcademy,
  eneAcademyTimelineBadges,
  infoEneAcademyHeroDevelop,
  infoEneAcademyHeroProfile,
  eneAcademyTimelineInfoDefault,
} from "../../mock/data"

const eneAcademy = (): JSX.Element => {
  return (
    <>
      <Menu pageName="ene-academy" />
      <Hero {...infoEneAcademyHeroDevelop} />
      <IconContent {...eneAcademyIconContent} />
      <section id="for-who">
        <Hero {...infoEneAcademyHeroProfile} />
      </section>
      <section id="step-by-step">
        <Timeline
          infoProps={eneAcademyTimelineInfoDefault}
          badges={eneAcademyTimelineBadges}
          infoTimeline={infoTimelineEneAcademy}
          pageName="ene-academy"
        />
      </section>
      <SubscriptionForm />
      <Footer />
    </>
  )
}

export default eneAcademy
